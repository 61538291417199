import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import AnimationWrapper from "../../components/animationWrapper"
import ContactSection from "../../components/contactSection"
import Container from "../../components/container"
import DownloadSection from "../../components/downloadSection"
import Header from "../../components/header"
import ProductTilesSection from "../../components/productTilesSection"
import TextSection from "../../components/textSection"
import Layout from "../../components/layout"

import "./produkty.css"

const KominyPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Zobacz!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <TextSection className="schiedel-title description-medium" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <section id="kominy-tiles-section" className="product-tiles-section">
        <ProductTilesSection data={data.kominyTiles} subcategoryName="Systemy kominowe" location="systemy-kominowe" />
      </section>

      <section id="pustaki-wenytlacyjne-tiles-section" className="product-tiles-section">
        <ProductTilesSection data={data.pustakiTiles} subcategoryName="Pustaki wentylacyjne" location="systemy-kominowe" />
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <DownloadSection data={data.kominyDownload} className="schiedel-download" location="Katalogi i broszury" />

      <DownloadSection data={data.kominyTechData} className="schiedel-download" location="Karty techniczne" />

      <DownloadSection data={data.kominyManuals} className="schiedel-download" location="Instrukcje montażu" />

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "systemy-kominowe" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          text
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    kominyTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {
          category: {eq: "systemy-kominowe"},
          subcategory: {eq: "kominy"},
        }
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          desc_short
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        id
        body
      }
    }
    pustakiTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {
          category: {eq: "systemy-kominowe"},
          subcategory: {eq: "pustaki-wentylacyjne"},
        }
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          # desc_short
          dimensions
          weight
          amount
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        id
        body
      }
    }
    kominyDownload: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(downloads)/"}},
        frontmatter: {category: {eq: "kominy"}}
        }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    kominyTechData: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(downloads)/"}},
        frontmatter: {category: {eq: "kominy-tech-data"}}
        }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    kominyManuals: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(downloads)/"}},
        frontmatter: {category: {eq: "kominy-manuals"}}
        }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default KominyPage
