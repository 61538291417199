import * as React from "react"

import AnimationWrapper from "./animationWrapper"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import "./downloadTile.css"

const ProductTile = ({ file }) => {


  return (
    <AnimationWrapper className="download-tile-wrapper" animationType="fadeInUp">

        <a href={file.frontmatter.file.publicURL} target="_blank" rel="noreferrer" className="download-tile">

          <div className="download-icon-wrapper"><FontAwesomeIcon icon={faFileArrowDown} /></div>
          <div className="download-file-name-wrapper">{file.frontmatter.name}</div>

        </a>

    </AnimationWrapper>
  )
}

export default ProductTile